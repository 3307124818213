:root {
  --bs-primary: #ae845f;
  --bs-primary-rgb: 174, 132, 95;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ae845f;
  --bs-btn-border-color: #ae845f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ae845f;
  --bs-btn-hover-border-color: #ae845f;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ae845f;
  --bs-btn-active-border-color: #ae845f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ae845f;
  --bs-btn-disabled-border-color: #0d6efd
}

.icon{
  position: absolute;
  width: 15px;
  font-size: 10px;
  height: 15px;
  top: 0;
  border-radius: 100%;
  background-color: black;
}
.banner{
  height: 100vh; 
  object-fit: cover;
}
nav{
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.4);
}
.swiper-pagination-bullet{
  background-color: #ae845f !important;
  width: 20px !important;
  height: 20px !important;
  background-clip: content-box !important;
  padding: 5px;
  border: 1px solid #ae845f;
}
.img-box{
  cursor: pointer;
}
.icon-box{
 transition: all 0.5s;
 bottom: -50%;
}
.img-box:hover .icon-box{
  bottom: 10%   ;
}
@media (max-width:575px) {
 .banner{
  height: 70vh !important;
 } 
}